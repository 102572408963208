.purchase-progress-container {
  width: 100%;
  /* margin: 20px 0; */
  /* padding: 20px; */
  background: #030711;
  border-radius: 12px;
  /* border: 1px solid #1D283A; */
  color: white;
}

.purchase-progress-container h3 {
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 15px;
  font-weight: 600;
  color: white;
}

.controls-purchase-container {
  width: 100%;
  /* margin: 10px; */
  border: 1px solid #1D283A;
  border-radius: 12px;
  /* background: #0F1629; */
  overflow: hidden;
}

.progress-controls {
  background: #0c1120;
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
  border-bottom: 1px solid #1D283A;
}

.play-button {
  background: #0F1629;
  color: #14B8A6;
  border: 1px solid #1D283A;
  padding: 8px 16px;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: all 0.2s ease;
}

.play-button:hover {
  background: #1a2235;
  border-color: #14B8A6;
}

.play-button.playing {
  background: #0F1629;
  color: #14B8A6;
  border: 1px solid #14B8A6;
}

.play-button.playing:hover {
  background: #0D9488;
}

.progress-slider {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.progress-slider input[type="range"] {
  width: 100%;
  background: #1D283A;
  height: 8px;
  appearance: none;
  border-radius: 4px;
}

.progress-slider input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  width: 18px;
  height: 18px;
  background: #1368E8;
  border-radius: 50%;
  cursor: pointer;
}

.step-label {
  text-align: center;
  margin-top: 5px;
  font-size: 14px;
  color: #7F8EA3;
}

.current-purchase {
  background: transparent;
  padding: 16px 20px;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
}

.purchase-details {
  display: flex;
  justify-content: space-between;
}

.purchase-info strong,
.cumulative-info strong {
  color: #14B8A6;
}

.price-progress-table {
  width: 640px;
  height: 300px;
  border-radius: 12px;
  overflow: visible;
  border: 1px #1D283A solid;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: transparent;
}

.price-progress-header {
  align-self: stretch;
  height: 48px;
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
  background: transparent;
}

.price-progress-row {
  align-self: stretch;
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 48px;
  border-bottom: none;
}

.token-cell, .price-cell, .change-cell {
  flex: 1 1 0;
  height: 48px;
  padding: 8px 14px;
  background: #0F1629;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  box-sizing: border-box;
}

.price-progress-header .token-cell,
.price-progress-header .price-cell,
.price-progress-header .change-cell {
  color: #7F8EA3;
  font-size: 14px;
  font-family: 'Helvetica', sans-serif;
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
  background: transparent;
}

.price-progress-row .token-cell,
.price-progress-row .price-cell {
  color: white;
  font-size: 16px;
  font-family: 'Helvetica', sans-serif;
  font-weight: 400;
  line-height: 24px;
}

.positive-change {
  color: #16A34A;
  font-size: 16px;
  font-family: 'Helvetica', sans-serif;
  font-weight: 400;
  line-height: 24px;
}

.negative-change {
  color: #F44336;
  font-size: 16px;
  font-family: 'Helvetica', sans-serif;
  font-weight: 400;
  line-height: 24px;
}

.PampiBrandingHeader .TableItem {
  flex: 1 1 0;
  height: 48px;
  padding: 8px 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  box-sizing: border-box;
}

.PampiBrandingContent {
  display: flex;
  align-items: center;
  gap: 8px;
}

.PampiBrandingLogo {
  height: 20px;
  width: auto;
}

.PampiBrandingText {
  color: #7F8EA3;
  font-size: 14px;
  font-family: 'Helvetica', sans-serif;
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
}

.support-button {
  background: #0F1629;
  color: #14B8A6;
  border: 1px solid #1D283A;
  padding: 4px 10px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease;
}

.support-button:hover {
  background: #1a2235;
  border-color: #14B8A6;
}

@media screen and (max-width: 650px) {
  .PampiBrandingLogo {
    height: 16px;
  }
  
  .PampiBrandingText {
    font-size: 13px;
  }
  
  .support-button {
    font-size: 11px;
    padding: 3px 8px;
  }
  
  .price-progress-table {
    width: 100%;
    height: 70%;
  }
  
  /* Add smaller font sizes for purchase details on mobile */
  .purchase-info,
  .cumulative-info {
    font-size: 14px;
  }
  
  .purchase-info strong,
  .cumulative-info strong {
    font-size: 14px;
  }
  
  .step-label {
    font-size: 13px;
  }
  
  .play-button {
    font-size: 14px;
    padding: 6px 14px;
  }
}

/* Add TickerIcon styling to match the ResultsSection */
.TickerIcon {
  width: 20px;
  height: 20px;
}

.TickerLabel {
  color: white;
  font-size: 16px;
  font-family: 'Helvetica', sans-serif;
  font-weight: 400;
  line-height: 24px;
}

/* Mobile styling for ticker icons */
@media screen and (max-width: 650px) {
  .TickerIcon {
    width: 17px;
    height: 17px;
  }
  
  /* Specific size for PLSX token icon */
  img[src*="plsx.png"].TickerIcon {
    width: 14px;
    height: 14px;
  }

  /* Specific size for INC token icon */
  img[src*="inc.png"].TickerIcon {
    width: 18px;
    height: 17px;
  }

  /* Specific size for HEX token icon */
  img[src*="hex.png"].TickerIcon {
    width: 18px;
    height: 15.4px;
  }
  
  .TickerLabel {
    font-size: 0.75rem;
  }
}

.donate-container {
  position: relative;
}

.donate-button {
  background: #0F1629;
  color: #14B8A6;
  border: 1px solid #1D283A;
  padding: 4px 10px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease;
}

.donate-button:hover {
  background: #1a2235;
  border-color: #14B8A6;
}

.eth-address-tooltip {
  position: absolute;
  top: -30px;
  right: 0;
  background: #0F1629;
  color: #14B8A6;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 11px;
  white-space: nowrap;
  border: 1px solid #1D283A;
  z-index: 10;
}

@media screen and (max-width: 650px) {
  .PampiBrandingLogo {
    height: 16px;
  }
  
  .PampiBrandingText {
    font-size: 13px;
  }
  
  .donate-button {
    font-size: 11px;
    padding: 3px 8px;
  }
  
  .eth-address-tooltip {
    font-size: 10px;
    top: -25px;
  }
  
  .price-progress-table {
    width: 100%;
    height: 70%;
  }
}