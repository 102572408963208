/* Base styles */
body {
  margin: 0;
  padding: 0;
  font-family: 'Helvetica', sans-serif;
  min-width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
}

/* Main container */
:root {
  width: 100vw;
  max-width: 100vw;
  min-width: 100vw;
  height: 100vh;
  background: #030711;
}

.NewUi {
  width: 100vw;
  max-width: 100vw;
  min-width: 100vw;
  height: 100vh;
  background: #030711;
  display: inline-flex;
  flex-direction: column;
}

/* Header section */
.AppHeader {
  align-self: stretch;
  width: 100%;
  max-width: 100vw;
  height: 68px;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 100;
  background: #030711;
}

.Header {
  width: 100%;
  max-width: 100vw;
  height: 64px;
  padding: 16px 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  gap: 16px;
}

.Pampi {
  color: white;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}

.Socials {
  padding: 8px 16px;
  background: #0F1629;
  border-radius: 6px;
  border: 1px solid #1D283A;
  display: flex;
  gap: 16px;
  align-items: center;
}

.CancelButton {
  color: #7F8EA3;
  font-size: 13px;
  background: none;
  border: none;
  padding: 4px 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s ease;
}

.CancelButton:hover {
  color: #fff;
}

/* Line separator */
.Line {
  align-self: stretch;
  height: 4px;
  padding: 2px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.Line1 {
  align-self: stretch;
  height: 0;
  border: 1px solid #1D283A;
}

/* Calculator section */
.calculator {
  align-self: stretch;
  /* min-height: calc(100vh - 68px);  */
  width: 100vw;
  max-width: 100vw;
  padding: 120px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  height: auto; /* Allow height to adjust based on content */
}

.Heading {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.PriceAppreciationSimulator {
  width: 640px;
  color: white;
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
}

.CalcSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  width: 640px;
  height: auto;
}

.Frame22 {
  width: 592px;
  height: 32px;
  align-self: stretch;
  justify-content: space-between;
  align-items: center;
  display: inline-flex;
}

.Frame22 .Calculator {
  color: white;
  font-size: 20px;
  font-family: Helvetica;
  font-weight: 600;
  line-height: 28px;
  word-wrap: break-word;
}

.Frame22 .Button {
  padding: 8px;
  background: #0F1629;
  border-radius: 6px;
  border: 1px solid #1D283A;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: flex;
}

.Frame22 .IconPlus {
  width: 17px;
  height: 17px;
  position: relative;
}

.Frame22 .Vector:first-child {
  width: 0;
  height: 9.33px;
  left: 8px;
  top: 3.33px;
  position: absolute;
  border: 1.33px solid white;
}

.Frame22 .Vector:last-child {
  width: 9.33px;
  height: 0;
  left: 3.33px;
  top: 8px;
  position: absolute;
  border: 1.33px solid white;
}


/* Chip section styles */
.ChipSection {
  display: inline-flex;
}

.ChipTabs {
  padding: 4px;
  border-radius: 32px;
  border: 1px solid #1D283A;
  display: flex;
  font-size: 0.85rem;
}

.ChipTabs > div {
  padding: 8px 14px;
  border-radius: 40px;
}

.CustomTab {
  background: #0F1629;
  border: 1px solid #1D283A;
}

.Selected {
  color: #14B8A6;
}

.Unselected {
  color: #F8FAFC;
}

.Continue {
  color: #000000;
}

/* Input section styles */
.InputSectionFrame {
  padding: 16px 24px;
  background: #030711;
  border-radius: 12px;
  border: 1px solid #1D283A;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  width: 640px;
  transition: height 0.3s ease;
  box-sizing: border-box;
}

.CalcCore {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 16px;
  transition: height 0.3s ease;
}

.CalcCore-Small {
  height: 184px;  /* Smaller height for 1-2 fields */
}

.CalcCore-Large {
  height: 248px;  /* Larger height for 3-4 fields */
}

.InputLabel {
  color: #7F8EA3;
}

.InputPrefix,
.InputSuffix {
  color: #7F8EA3;
}

.InfoInput {
  background: transparent;
  border: 1px solid #1D283A;
  border-radius: 10px;
  color: #7F8EA3;
  font-size: 14px;
  font-family: Helvetica;
  font-weight: 400;
  width: 95px;
  padding: 1px 1px;
  outline: none;
  text-align: center;
}

.InfoInputETH {
  background: transparent;
  border: 1px solid #1D283A;
  border-radius: 10px;
  color: #7F8EA3;
  font-size: 14px;
  font-family: Helvetica;
  font-weight: 400;
  width: 48px;
  padding: 1px 1px;
  outline: none;
  text-align: center;
}

.InfoInput:focus {
  border-bottom: 1px solid #14B8A6;
}

/* Update existing InfoLayer class */
.InfoLayer {
  color: #7F8EA3;
  align-self: stretch;
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding: 0 4px;
  transition: height 0.3s ease;
}

.InfoLayer-Small {
  height: 32px;  /* Smaller height for 1-2 fields */
}

.InfoLayer-Large {
  height: 40px;  /* Larger height for 3-4 fields */
}

/* Results section */
.ResultsSection {
  width: 640px;
  height: 335px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  display: none; /* Hide by default */
}

.ResultsSection.visible {
  display: flex; /* Show when results are available */
}

/* Add increased height when visualizations are shown */
.ResultsSection.visualization-mode {
  height: 550px;
}

.ResultsHeading {
  align-self: stretch;
  height: 55px;
  background: #030711;
  justify-content: flex-start;
  align-items: center;
  display: inline-flex;
}

.ResultsHeading-heading {
  align-self: stretch;
  width: 100%;
  height: 55px;
  background: #030711;
  justify-content: flex-start;
  align-items: center;
  display: inline-flex;
}

.Results {
  color: white;
  font-size: 20px;
  font-family: Helvetica;
  font-weight: 600;
  line-height: 28px;
  word-wrap: break-word;
  padding: 0 16px;
  margin-right: auto; /* Push to left */
}

/* .ShareButton {
  padding: 8px 16px;
  background: #0F1629;
  border-radius: 6px;
  border: 1px solid #1D283A;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  margin-left: auto;
} */

.ShareButton {
  display: none; /* Hide by default */
}


.ResultsHeadings {
  align-self: stretch;
  height: 48px;
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.Ticker, .InitialPrice, .NewPrice {
  flex: 1 1 0;
  height: 48px;
  padding: 8px 14px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  box-sizing: border-box;
}

.TickerText, .InitialPriceText, .NewPriceText {
  color: #7F8EA3;
  font-size: 14px;
  font-family: 'Helvetica', sans-serif;
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
}

.TableRow {
  align-self: stretch;
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 48px;
}

.TableItem {
  flex: 1 1 0;
  height: 48px;
  padding: 8px 14px;
  background: #0F1629;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  box-sizing: border-box;
}

.TickerIcon {
  width: 20px;
  height: 20px;
}

.TickerLabel, .PriceLabel {
  color: white;
  font-size: 16px;
  font-family: 'Helvetica', sans-serif;
  font-weight: 400;
  line-height: 24px;
}

.IncreaseIndicator {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ArrowForward {
  width: 20px;
  height: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Vector.fa-arrow-up {
  color: #16A34A;
  font-size: 14px;
}

.IncreaseText {
  color: #16A34A;
  font-size: 16px;
  font-family: 'Helvetica', sans-serif;
  font-weight: 400;
  line-height: 24px;
}

/* Table styles */
.Table {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  overflow: visible;
  border: 1px #1D283A solid;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

/* calc-btn styles */
.calc-btn {
  width: 592px;
  height: 31px;
  padding: 8px 16px;
  background: #14B8A6;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  transition: all 0.2s ease;
  box-sizing: border-box;
}

.calc-btn:disabled {
  background: #0D9488;
  cursor: not-allowed;
  opacity: 0.8;
}

/* Add hover state */
.calc-btn:not(:disabled):hover {
  background: #0D9488;
  transform: translateY(-1px);
}

/* Add active/clicked state */
.calc-btn:not(:disabled):active {
  background: #0F766E;
  transform: translateY(1px);
}

/* Utility classes */
.flex-1 {
  flex: 1 1 0;
}

.text-green {
  color: #16A34A;
}

.text-white {
  color: white;
}

.social-icon {
  width: 20px;
  height: 20px;
  color: white;
  cursor: pointer;
}

.social-icon:hover {
  color: #14B8A6;
}

.InputFieldsContainer {
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 16px;
  width: 592px;
  height: 140px;
  margin-bottom: 16px;
}

.InputRow {
  display: flex;
  gap: 16px;
  width: 100%;
}

/* Base InputField styles */
.InputField {
  display: flex;
  /* height: 64px; */
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: #020204;
  border-radius: 8px;
  border: 1px solid #1D283A;
}

/* First row styles */
.InputField-FirstRow-Large {
  width: 592px;
}

.InputField-FirstRow-Small {
  width: 288px;
}

/* Second row styles */
.InputField-SecondRow-Large {
  width: 592px;
}

.InputField-SecondRow-Small {
  width: 288px;
}

.amount-input {
  display: flex;
  align-items: center;
  color: white;
  font-size: 18px;
  font-weight: 500;
}

.dollar-prefix {
  margin-right: 0px;
}

.amount-input input {
  background: transparent;
  border: none;
  color: white;
  font-size: 18px;
  font-weight: 500;
  width: 150px;
  outline: none;
}

.token-dropdown {
  padding: 0.1rem 0.3rem;
  background: #0F1629;
  border-radius: 40px;
  border: 1.5px solid #1D283A;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.token-dropdown select {
  background: transparent;
  border: none;
  color: white;
  font-size: 12px;
  font-weight: 500;
  outline: none;
  cursor: pointer;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 20px;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 12px;
}

/* Hide default arrow in IE */
.token-dropdown select::-ms-expand {
  display: none;
}

.token-dropdown select option {
  background: #0F1629;
  color: white;
}

.ReflexivityHeader {
  align-self: stretch;
  height: 48px;
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.ReflexivityHeader .TableItem {
  flex: 1 1 0;
  height: 48px;
  padding: 8px 14px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  background: transparent;
  box-sizing: border-box;
}

.ReflexivityText {
  color: #7F8EA3;
  font-size: 14px;
  font-family: 'Helvetica', sans-serif;
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
}

.LogoSection {
  display: flex;
  align-items: center;
  gap: 8px;
}

.PampiLogo {
  height: 32px;
  width: auto;
  margin-left: 16px;
}

/* Add these new styles */
.DisclaimerSection {
  /* width: 640px; */
  padding: 12px;
  background: #0F1629;
  border-radius: 12px;
  border: 1px solid #1D283A;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.DisclaimerTitle {
  color: #14B8A6;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}

.DisclaimerText {
  color: #7F8EA3;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}

/* Add after your existing styles */
.price-tracker-container {
  display: none; /* hide the price tracker container */
  padding: 0 48px;
  margin-top: 69px;
}

/* Add new HeaderDisclaimer styles */
.HeaderDisclaimer {
  background: #0F1629;
  border-radius: 6px;
  border: 1px solid #1D283A;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  max-width: 90%;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.HeaderDisclaimer .DisclaimerText {
  color: #7F8EA3;
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  margin: 0;
}

.DisclaimerCloseButton {
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.DisclaimerCloseButton .CancelButton {
  color: #7F8EA3;
  font-size: 13px;
}


/* Update ETH Slider styles for multiplier sections */
.ETHSlider {
  width: 100px;
  height: 4px;
  -webkit-appearance: none;
  appearance: none;
  background: linear-gradient(to right,
    #1D283A 0%,
    #1D283A 25%,
    #14B8A6 50%,
    #14B8A6 75%,
    #fd0202 100%
  );
  outline: none;
  border-radius: 2px;
  margin-left: 8px;
  cursor: pointer;
}

/* Add break point markers with multiplier indicators */
.ETHSlider::before {
  content: '';
  position: absolute;
  width: 100px;
  height: 12px;
  background: repeating-linear-gradient(
    to right,
    transparent,
    transparent calc(25% - 1px),
    #1D283A calc(25% - 1px),
    #1D283A 25%,
    transparent 25%
  );
  pointer-events: none;
  margin-top: -4px;
}

/* Update slider thumb styles */
.ETHSlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 12px;
  height: 12px;
  background: #14B8A6;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.2s ease;
  position: relative;
  z-index: 1;
}

.ETHSlider::-moz-range-thumb {
  width: 12px;
  height: 12px;
  background: #14B8A6;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  transition: background 0.2s ease;
  position: relative;
  z-index: 1;
}

/* Update InputGroup to accommodate slider */
.InputGroup {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #7F8EA3;
  font-size: 14px;
  font-family: Helvetica;
  font-weight: 400;
  line-height: 20px;
}

/* Update info icon styles */
.info-icon-wrapper {
  display: inline-flex;
  align-items: center;
  margin-left: 5px;
  position: relative;
}

.info-icon {
  color: #7F8EA3;
  font-size: 14px;
  cursor: pointer;
  transition: color 0.2s ease;
}

.info-icon:hover {
  color: #14B8A6;
}

/* Replace the title-based tooltip with a custom tooltip */
.info-tooltip {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #0F1629;
  color: white;
  padding: 12px 16px;
  border-radius: 6px;
  font-size: 12px;
  width: 280px;
  max-width: 90vw;
  text-align: center;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  border: 1px solid #1D283A;
  font-weight: normal;
  line-height: 1.4;
}

/* Remove the arrow since it's now centered on screen */
.info-tooltip::after {
  display: none;
}

/* Mobile-specific tooltip styles */
@media screen and (max-width: 650px) {
  .info-tooltip {
    width: 85vw;
    font-size: 11px;
    padding: 10px 14px;
    line-height: 1.3;
  }
  
  .info-icon {
    font-size: 16px; /* Slightly larger touch target on mobile */
    padding: 2px;
  }
}

/* Add these spinner styles */
.spinner {
  width: 16px;
  height: 16px;
  border: 2px solid transparent;
  border-top: 2px solid #000;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 0;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.Continue {
  display: flex;
  align-items: center;
  height: 24px;
}

.PampiBrandingHeader {
  align-self: stretch;
  height: 48px;
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.PampiBrandingHeader .TableItem {
  flex: 1 1 0;
  height: 48px;
  padding: 8px 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  box-sizing: border-box;
}

.PampiBrandingContent {
  display: flex;
  align-items: center;
  gap: 8px;
}

.PampiBrandingLogo {
  height: 20px;
  width: auto;
}

.PampiBrandingText {
  color: #7F8EA3;
  font-size: 14px;
  font-family: 'Helvetica', sans-serif;
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
}

.token-icon {
  width: 20px;
  height: 20px;
  margin-right: 2px;
}

/* Specific size for HEX token icon */
img[src*="hex.png"].token-icon {
  width: 20px;
  height: 17.4px;
  margin-right: 2px;
}

/* Specific size for PLSX token icon */
img[src*="plsx.png"].token-icon {
  width: 18px;
  height: 18px;
  margin-right: 1px;
}

/* How it works button */
.how-it-works-btn {
  padding: 8px 16px;
  background: #0F1629;
  border-radius: 6px;
  border: 1px solid #1D283A;
  cursor: pointer;
  transition: all 0.2s ease;
}

.how-it-works-btn .Note {
  color: #14B8A6;
  font-size: 14px;
  font-weight: 500;
}

.how-it-works-btn:hover {
  background: #1a2235;
}

/* Modal styles */
.how-it-works-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 98vw;
  height: 98vh;
  background: rgba(3, 7, 17, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: auto;
  padding: 20px;
}

.modal-content {
  background: #0F1629;
  border: 1px solid #1D283A;
  border-radius: 12px;
  padding: 32px;
  max-width: 800px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  color: #F8FAFC;
  text-align: center;
}

.modal-content h2 {
  color: #14B8A6;
  font-size: 24px;
  margin-bottom: 24px;
  text-align: center;
}

.modal-content h3 {
  color: #14B8A6;
  font-size: 20px;
  margin: 24px 0 16px;
  text-align: center;
}

.main-description {
  line-height: 1.6;
  margin-bottom: 24px;
  text-align: center;
}

.section {
  margin-bottom: 32px;
  text-align: center;
}

.section ul, .section ol {
  padding-left: 0;
  margin: 12px auto;
  list-style-position: inside;
}

.section li {
  margin-bottom: 8px;
  line-height: 1.5;
  text-align: center;
}

.steps li {
  margin-bottom: 12px;
  text-align: center;
}

.close-modal-btn {
  width: 100%;
  padding: 12px;
  background: #14B8A6;
  color: #000000;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  margin-top: 24px;
  transition: all 0.2s ease;
}

.close-modal-btn:hover {
  background: #0D9488;
}

.modal-close-x {
  position: sticky;
  top: 0;
  float: right;
  margin: -8px -8px 0 0;
  background: #0F1629;
  border: none;
  color: #7F8EA3;
  font-size: 24px;
  cursor: pointer;
  padding: 8px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s ease;
  z-index: 2;
}

.modal-close-x:hover {
  color: #F8FAFC;
}

/* Update mobile styles */
@media screen and (max-width: 650px) {
  .Header {
    padding: 2.5vw 2vw;
  }

  /* .calculator {
    padding-top: 60px; 
    padding-bottom: 120px;  
  } */

  .calculator {
    padding-top: 9vw;
    padding-bottom: 18vw;
  }

  .PriceAppreciationSimulator {
    width: auto;
    font-size: 5vw;
  }

  .CalcSection {
    width: 98.5vw;
    height: auto;
  }

  .ChipTabs {
    font-size: 0.74rem;
  }

  .InputSectionFrame {
    width: 98vw;
    border: 1.5px solid #1D283A;
  }

  /* .InputSectionFrame-Small {
    height: 640px;
  } */

  .InputSectionFrame-Large {
    height: 22rem;
  } 

  .Frame22 {
    width: auto;
    height: auto;
  }

  .InputFieldsContainer {
    width: 86.5vw;
    height: auto;
    /* padding: 0.1rem 0.1rem; */
    /* margin-bottom: 4.5vw; */
  }

  .calc-btn {
    width: 100%;
    height: 31px;
  }

  .InputRow {
    gap: 3vw;
  }

  .InputGroup {
    gap: 1.4vw;
    font-size: 13px;
    width: 60%;
  }

  .InputField {
    height: 7vw;
    padding: 10px;
    padding-left: 10px;
    padding-right: 10px;
    border: 1.5px solid #1D283A;
  }

  .InputField-FirstRow-Large {
    width: 100%;
  }

  .InputField-FirstRow-Small {
    width: 40vw;
  }

  .amount-input {
    font-size: 2.9vw;
    width: 20vw;
  }

  .amount-input input {
    font-size: 95%;
    width: 20vw;
  }

  /* .token-icon {
    display: none;
  } */

  .token-icon {
    width: 17px;
    height: 17px;
  }
  
  /* Specific size for PLSX token icon */
  img[src*="plsx.png"].token-icon {
    width: 14px;
    height: 14px;
  }

  /* Specific size for INC token icon */
  img[src*="inc.png"].token-icon {
    width: 18px;
    height: 17px;
  }

  /* Specific size for HEX token icon */
  img[src*="hex.png"].token-icon {
    width: 18px;
    height: 15.4px;
  }
  

  .token-dropdown {
    padding: 0.1rem 0.2rem;
    width: 3.5rem;
  }

  .token-dropdown select {
    font-size: 10.5px;
    outline: none;
    border: none;
    color: white;
    padding-right: 15px;       /* Space for custom arrow */
    background-position: right 2px center;
    background-size: 12px;
  }

  .token-dropdown select option {
    font-size: 12px;
    outline: none;
    border: none;
  }

  .InfoLayer-Large {
    height: 4rem;
  }

  .InputLabel {
    width: 40%;
    font-size: 12.5px;
    white-space: normal; /* Allow text to wrap */
    word-wrap: break-word; /* Break long words onto the next line */
  }
  .eth-label {
    width: 22%;
    font-size: 12.5px;
  }

  .InfoInput {
    font-size: 12px;
    width: 60%;
  }
  .InfoInputETH {
    font-size: 12px;
    width: 25%;
  }

  .ETHSlider {
    width: 15.8vw;
  }
  .ETHSlider::before {
    width: 16.8vw;
    height: 12px;
    pointer-events: none;
    margin-top: -4px;
  }

  .DisclaimerSection {
    width: auto;
    height: auto;
  }
  .DisclaimerText {
    font-size: 13px;
  }
  .CancelButton {
    font-size: 11px;
  }


    /* Results section */

  .ResultsSection {
    width: 99vw;
    height: 100%;
  }

  .ShareButton {
    display: none; /* Hiding the ShareButton */
  }


  /* Modal styles */

  .how-it-works-modal {
    width: 93vw;
    height: 98vh;
  }

  .modal-content {
    padding: 15px;
    width: 80vw;
    height: 95vh;
  }

  .modal-content h2 {
    font-size: 20px;
  }

  .modal-content h3 {
    font-size: 18px;
  }

  .section {
    margin-bottom: 24px;
  }  

  .TickerText, .InitialPriceText, .NewPriceText {
    font-size: 13px;
  }
  
  .Table {
    width: 98vw;
    height: 48vh;
  }

  .TableRow {
    height: 48px;
  }
  
  .PriceLabel {
    font-size: 0.9rem;
  }
  .TickerLabel {
    font-size: 0.75rem;
    max-width: 100%;
  }

  .Vector.fa-arrow-up {
    font-size: 0.8rem;
  }

  .IncreaseText {
    font-size: 0.9rem;
  }
  
  .price-tracker-container {
    padding: 0 16px;
    margin-top: 10px;
  }

  .PampiBrandingLogo {
    height: 16px;
  }
  
  .PampiBrandingText {
    font-size: 13px;
  }

  .HeaderDisclaimer {
    padding: 6px 12px;
  }

  .HeaderDisclaimer .DisclaimerText {
    font-size: 11px;
    line-height: 14px;
  }

  .DisclaimerCloseButton .CancelButton {
    font-size: 11px;
  }

  .DisclaimerOverlay {
    width: 90%;
  }

  .main-description {
    font-size: 14px;
    line-height: 1.5;
  }

  .section li {
    font-size: 14px;
    line-height: 1.4;
  }

  .close-modal-btn {
    padding: 10px;
    font-size: 14px;
  }

  .modal-close-x {
    top: 12px;
    right: 12px;
    font-size: 20px;
    padding: 6px;
  }

  /* Add increased height for mobile visualization mode */
  .ResultsSection.visualization-mode {
    height: auto;
    min-height: 550px;
  }
  .ResultsHeading {
    height: 80px;
  }
  .ResultsHeading-heading {
    font-size: 18px;
  }
}

@media screen and (max-width: 384px) {
  /* .ShareButton {
    display: none; 
  }
  .ChipTabs {
    font-size: 0.7rem;
  }
  .InputLabel {
    font-size: 11.5px;
  }

  .InfoInput {
    font-size: 12px;
  }

  .InfoInputETH {
    font-size: 12px;
  }
  .ETHSlider {
    width: 10vw;
  }
  .InputGroup {
    gap: 4px;
  } */



  .Header {
    padding: 2.5vw 2vw;
  }

  /* .calculator {
    padding-top: 60px; 
    padding-bottom: 120px;  
  } */

  .calculator {
    padding-top: 9vw;
    padding-bottom: 18vw;
  }

  .PriceAppreciationSimulator {
    width: auto;
    font-size: 5vw;
  }

  .CalcSection {
    width: 98.5vw;
  }

  .ChipTabs {
    font-size: 0.64rem;
  }

  .InputSectionFrame {
    width: 98vw;
    border: 1.5px solid #1D283A;
  }

  /* .InputSectionFrame-Small {
    height: 640px;
  } */

  .InputSectionFrame-Large {
    height: 22rem;
  } 

  .Frame22 {
    width: auto;
    height: auto;
  }

  .InputFieldsContainer {
    width: 87.5vw;
    height: auto;
    /* padding: 0.1rem 0.1rem; */
    /* margin-bottom: 4.5vw; */
  }

  .calc-btn {
    width: 100%;
    height: 31px;
  }

  .InputRow {
    gap: 3vw;
  }

  .InputGroup {
    gap: 1.4vw;
    font-size: 13px;
    width: 60%;
  }

  .InputField {
    height: 7vw;
    padding: 10px;
    padding-left: 10px;
    padding-right: 10px;
    border: 1.5px solid #1D283A;
  }

  .InputField-FirstRow-Large {
    width: 100%;
  }

  .InputField-FirstRow-Small {
    width: 40vw;
  }

  .amount-input {
    font-size: 2.9vw;
    width: 20vw;
  }

  .amount-input input {
    font-size: 95%;
    width: 20vw;
  }

  /* .token-icon {
    display: none;
  } */

  .token-icon {
    width: 17px;
    height: 17px;
  }
  
  /* Specific size for PLSX token icon */
  img[src*="plsx.png"].token-icon {
    width: 14px;
    height: 14px;
  }

  /* Specific size for INC token icon */
  img[src*="inc.png"].token-icon {
    width: 18px;
    height: 17px;
  }

  /* Specific size for HEX token icon */
  img[src*="hex.png"].token-icon {
    width: 18px;
    height: 15.4px;
  }
  

  .token-dropdown {
    padding: 0.1rem 0.2rem;
    width: 3.5rem;
  }

  .token-dropdown select {
    font-size: 10.5px;
    outline: none;
    border: none;
    color: white;
    padding-right: 15px;       /* Space for custom arrow */
    background-position: right 2px center;
    background-size: 12px;
  }

  .token-dropdown select option {
    font-size: 12px;
    outline: none;
    border: none;
  }

  .InfoLayer-Large {
    height: 4rem;
  }

  .InputLabel {
    width: 37%;
    font-size: 11px;
    white-space: normal; /* Allow text to wrap */
    word-wrap: break-word; /* Break long words onto the next line */
  }
  .eth-label {
    width: 22%;
    font-size: 11px;
  }

  .InfoInput {
    font-size: 11px;
    width: 60%;
  }
  .InfoInputETH {
    font-size: 11px;
    width: 25%;
  }

  .ETHSlider {
    width: 15.5vw;
  }
  .ETHSlider::before {
    width: 16vw;
    height: 12px;
    pointer-events: none;
    margin-top: -4px;
  }

  .DisclaimerSection {
    width: auto;
    height: auto;
  }
  .DisclaimerText {
    font-size: 13px;
  }
  .CancelButton {
    font-size: 11px;
  }


    /* Results section */

  .ResultsSection {
    width: 99vw;
    height: 100%;
  }

  .ShareButton {
    display: none; /* Hiding the ShareButton */
  }
  
  .TickerText, .InitialPriceText, .NewPriceText {
    font-size: 13px;
  }
  
  .Table {
    width: 99vw;
  }

  .TableRow {
    height: 48px;
  }
  
  .PriceLabel {
    font-size: 0.9rem;
  }
  .TickerLabel {
    font-size: 0.75rem;
    max-width: 100%;
  }

  .Vector.fa-arrow-up {
    font-size: 0.8rem;
  }

  .IncreaseText {
    font-size: 0.9rem;
  }

  .PampiBrandingLogo {
    height: 16px;
  }
  
  .PampiBrandingText {
    font-size: 13px;
  }

  .HeaderDisclaimer {
    padding: 6px 12px;
  }

  .HeaderDisclaimer .DisclaimerText {
    font-size: 11px;
    line-height: 14px;
  }

  .DisclaimerCloseButton .CancelButton {
    font-size: 11px;
  }

  .DisclaimerOverlay {
    width: 90%;
  }  


  /* Modal styles */
  .how-it-works-modal {
    width: 90vw;
    height: 98vh;
  }

  .modal-content {
    padding: 15px;
    width: 80vw;
    height: 95vh;
  }

  .modal-content h2 {
    font-size: 18px;
  }

  .modal-content h3 {
    font-size: 16px;
  }

  .main-description {
    font-size: 13px;
  }

  .section li {
    font-size: 12px;
  }

  .close-modal-btn {
    padding: 8px;
    font-size: 13px;
  }

  .modal-close-x {
    top: 8px;
    right: 8px;
    font-size: 18px;
    padding: 4px;
  }

  /* Add increased height for mobile visualization mode */
  .ResultsSection.visualization-mode {
    height: auto;
    min-height: 550px;
  }
}

/* Add to your App.css */
.results-buttons {
  display: flex;
  gap: 10px;
}

.visualization-toggle-btn {
  padding: 8px 16px;
  background: #0F1629;
  border-radius: 6px;
  border: 1px solid #14B8A6;
  cursor: pointer;
  transition: all 15.9s ease;
  position: relative;
  overflow: hidden;
}

.visualization-toggle-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(20, 184, 166, 0.4),
    transparent
  );
  animation: borderShine 3s infinite;
  z-index: 1;
}

@keyframes borderShine {
  0% {
    left: -100%;
  }
  40% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
}

.visualization-toggle-btn .Note {
  color: #14B8A6;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  z-index: 2;
}

.visualization-toggle-btn:hover {
  background: #1a2235;
  border-color: #14B8A6;
}

/* Flipped colors for "show results" state */
.visualization-toggle-btn.show-results {
  background: #0F1629;
  border: 1px solid #14B8A6;
  overflow: initial;
}

.visualization-toggle-btn.show-results::before {
  display: none; /* Disable animation for show-results state */
}

.visualization-toggle-btn.show-results .Note {
  color: #14B8A6;
}

.visualization-toggle-btn.show-results:hover {
  background: #0D9488;
}

.visualization-section {
  /* margin-top: 20px; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media (max-width: 768px) {
  .results-buttons {
    flex-direction: column;
    gap: 5px;
  }
  
  .visualization-section {
    /* margin-top: 10px; */
    gap: 10px;
  }
}

